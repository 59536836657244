import $ from "jquery";
import _ from "underscore";
import IsPassiveSupport from "../lib/IsPassiveSupport";
import ModalLib from "../lib/ModalLib";
import UA from "../lib/UA";

export default class Modal {
  constructor() {
    this.container = document.getElementById('container');
    this.modal = document.getElementById("modal");
    this.modalInner = this.modal.getElementsByClassName("js-modalInner");
    this.target = document.getElementsByClassName("js-modal");
    this.overlay = this.modal.getElementsByClassName("modal__overlay")[0];
    this.breakPoint = 768;
    this.fixedPosY = 0;
    this.targetModal;
    this.spModalMode = 0; // 0: close, 1: default, 2: fullscreen
    this.changeHeightFlg = false;
    this.outsideTouchedFlg = false;
    this.lockFlg = false;

    // lib
    this.modalLib = new ModalLib();
    this.ua = new UA();

    this.init();
  }

  init() {
    this.fixedFlg = (this.ua.device() == "sp") ? true : false;
    this.setModalHeight();
    this.bindEvents();
  }

  bindEvents() {
    const isPassive = new IsPassiveSupport();

    // Open Modal
    this.debouncedOnTargetClick = _.debounce(elm => {
      const id = elm.dataset.modal;
      const mode = elm.dataset.mode;
      const direction = elm.dataset.direction || null;
      if (id) {
        const targetModal = document.getElementById(id);
        if (targetModal) {
          this.targetModal = targetModal;
        }
        this.spModalMode = 1;
        if (mode) {
          if (mode == "full") {
            this.spModalMode = 2;
          }
        }
        this.openModal(id, direction);
      }
    }, 0);
    $(this.target).on("click", e => {
      e.preventDefault();
      e.stopPropagation();
      if (this.lockFlg) return false;
      this.lockFlg = true;
      this.debouncedOnTargetClick(e.currentTarget);
    });

    // CloseModal
    const closeModal = document.getElementsByClassName('js-modalClose');
    this.debouncedOnOverlayClick = _.debounce(() => {
      this.closeModal();
    }, 0);
    $(this.overlay).on("click", e => {
      e.preventDefault();
      e.stopPropagation();
      this.debouncedOnOverlayClick();
    });
    if (closeModal.length) {
      Array.prototype.forEach.call(closeModal, elm => {
        elm.addEventListener("click", e => {
          e.preventDefault();
          e.stopPropagation();
          this.debouncedOnOverlayClick();
        });
        elm.addEventListener("keypress", e => {
          // Enter
          if (e.keyCode === 13) {
            this.debouncedOnOverlayClick();
          }
        });
      });
    }

    const getTouches = e => {
      return e.touches || e.originalEvent.touches;
    }

    // SP Modal Swipe Action
    const spModalCloseElm = document.getElementsByClassName('js-modalCloseSp');
    let swipeStartFlg = false;
    let innerScrollStartUpFlg = false;
    let innerScrollStartDownFlg = false;
    let swipeStartY = null;
    let swipeMoveY = null;
    let swipeModalHeight = null;
    let startEvent = "mousedown";
    let moveEvent = "mousemove";
    let endEvent = "mouseup";

    if ('ontouchstart' in window || navigator.msPointerEnabled) {
      startEvent = "touchstart";
      moveEvent = "touchmove";
      endEvent = "touchend";
    }

    if (this.modalInner.length) {
      Array.prototype.forEach.call(this.modalInner, elm => {
        const spSwipeBar = elm.getElementsByClassName('js-modalCloseSp')[0];
        if (spSwipeBar) {
          elm.addEventListener(startEvent, e => {
            if (this.targetModal.classList.contains("-onFocus")) {
              return false;
            }
            if (window.innerWidth < this.breakPoint) {
              swipeStartFlg = true;
              innerScrollStartUpFlg = false;
              innerScrollStartDownFlg = false;
              this.changeHeightFlg = true;
              if (!(
                e.target.closest('.js-modalInnerContent') ||
                e.target.closest('.js-modalRefinementInnerContent')
              )) {
                this.outsideTouchedFlg = true;
              }
              if (startEvent == "touchstart") {
                const touch = getTouches(e)[0];
                swipeStartY = touch.clientY;
              } else {
                swipeStartY = e.clientY;
              }
              swipeMoveY = swipeStartY;
              swipeModalHeight = this.targetModal.clientHeight;
            }
          }, isPassive.check() ? { passive: true } : false);

          elm.addEventListener(endEvent, e => {
            if (window.innerWidth < this.breakPoint) {
              this.outsideTouchedFlg = false;
              swipeStartFlg = false;
              if (this.targetModal) {
                this.targetModal.classList.remove('-move');
              }
              const diff = parseInt((swipeMoveY - swipeStartY), 10);
              if (swipeMoveY) {
                if (this.spModalMode == 2) {
                  if (this.changeHeightFlg == true && diff > 30) {
                    // close
                    this.closeModal();
                  }
                } else {
                  if (diff < -30) {
                    // full screen
                    this.spModalMode = 2;
                  } else if (diff > 30) {
                    // close
                    this.closeModal();
                  }
                }
                this.setModalHeight();
              }
            }
          }, isPassive.check() ? { passive: true } : false);
        }
      });
    }

    if (spModalCloseElm.length) {
      document.addEventListener(moveEvent, e => {
        if (window.innerWidth < this.breakPoint) {
          if (this.targetModal.classList.contains("-onFocus")) {
            return false;
          }
          if (swipeStartFlg) {
            if (startEvent == "touchstart") {
              swipeMoveY = e.touches[0].clientY;
            } else {
              swipeMoveY = e.clientY;
            }
            const diff = parseInt((swipeMoveY - swipeStartY), 10);

            if (this.targetModal) {
              const innerContent = (() => {
                let innerContent = this.getTargetModalContent(this.targetModal);

                // 絞り込みモーダルの場合はアクティブな要素を返却
                const refinementWrap = this.targetModal.getElementsByClassName('js-modalRefinementWrap');
                if (refinementWrap.length) {
                  Array.prototype.forEach.call(refinementWrap, elm => {
                    if (!elm.classList.contains("-hide")) {
                      const refinementInnerContent = elm.getElementsByClassName("js-modalRefinementInnerContent")[0];
                      if (refinementInnerContent) {
                        innerContent = refinementInnerContent;
                      }
                    }
                  });
                }

                return innerContent;
              })();

              if (innerContent) {
                if (this.spModalMode == 2) {
                  if (diff < 0) {
                    // up
                    if (!innerScrollStartDownFlg) {
                      innerScrollStartUpFlg = true;
                      this.changeHeightFlg = false;
                    }
                  } else {
                    // down
                    if (innerContent.scrollTop > 0 && !this.outsideTouchedFlg) {
                      this.changeHeightFlg = false;
                    }
                    if (!innerScrollStartUpFlg) {
                      innerScrollStartDownFlg = true;
                    }
                  }
                }
              }
              if (
                this.spModalMode != 2 ||
                (this.spModalMode == 2 && this.changeHeightFlg)
              ) {
                e.preventDefault();
                const targetModalContent = this.getTargetModalContent(this.targetModal);
                this.targetModal.classList.add('-move');
                this.targetModal.style.height = swipeModalHeight - diff + "px";
              }
            }
          }
        }
      }, { passive: false });
    }

    // input focus event
    // ソフトウェアキーボード出現時に高さが変わるので調整
    const inputElms = this.container.getElementsByTagName("input");
    let focusTimeoutId;
    let focusFlg = false;
    this.onFocusOutEvent = _.debounce(() => {
      if (!focusFlg) {
        this.setModalHeight();
        clearTimeout(focusTimeoutId);
        focusTimeoutId = _.delay(() => {
          this.targetModal.classList.remove('-onFocus');
        }, 450);
      }
    }, 600);
    if (inputElms.length) {
      Array.prototype.forEach.call(inputElms, elm => {
        if (_.contains(["text", "search", "tel", "url", "email", "password"], elm.type)) {
          elm.addEventListener("focus", e => {
            e.preventDefault();
            if (this.targetModal) {
              focusFlg = true;
              clearTimeout(focusTimeoutId);
              this.targetModal.classList.add('-onFocus');
              this.setModalHeight();
            }
          });
        }
        elm.addEventListener("focusout", e => {
          e.preventDefault();
          focusFlg = false;
          if (this.targetModal) {
            this.onFocusOutEvent();
          }
        });
      });
    }

    // resize event
    const debouncedOnResize = _.debounce(() => {
      this.setModalHeight();
    }, 0);
    window.addEventListener("resize", () => {
      window.requestAnimationFrame(debouncedOnResize);
    }, false);
  }

  // モーダル高さ設定
  setModalHeight() {
    if (window.innerWidth < this.breakPoint) {
      // sp
      const wh = window.innerHeight;

      if (this.spModalMode == 1) {
        // default
        if (this.modalInner.length) {
          Array.prototype.forEach.call(this.modalInner, elm => {
            elm.style.height = wh / 2 + "px";
          });
        }
        if (this.targetModal) {
          const innerContent = this.getTargetModalContent(this.targetModal);
          if (innerContent) {
            innerContent.style.height = "";
            const modalRect = this.targetModal.getBoundingClientRect();
            const modalContentRect = innerContent.getBoundingClientRect();
            const contentHeight = modalRect.height - (modalContentRect.top - modalRect.top);
            innerContent.style.height = contentHeight + "px";
          }
        }
      } else if (this.spModalMode == 2) {
        // fullscreen
        if (this.modalInner.length) {
          Array.prototype.forEach.call(this.modalInner, elm => {
            elm.style.height = wh - 24 + "px";
          });
        }
        const innerContent = this.getTargetModalContent(this.targetModal);
        if (innerContent) {
          const modalRect = this.targetModal.getBoundingClientRect();
          const modalContentRect = innerContent.getBoundingClientRect();
          const contentHeight = wh - 24 - (modalContentRect.top - modalRect.top);
          if (modalContentRect.height != 0) {
            innerContent.style.height = contentHeight + "px";
          }
        }
      }
    } else {
      // pc
      if (this.targetModal) {
        const innerContent = this.getTargetModalContent(this.targetModal);
        const modalConfirm = this.targetModal.getElementsByClassName('modal__confirm')[0];
        if (this.targetModal.getElementsByClassName('modalContent')[0]) {
          this.targetModal.getElementsByClassName('modalContent')[0].style.height = "";
        }
        if (innerContent) {
          this.targetModal.style.height = "";
          innerContent.style.height = "";
          const modalRect = this.targetModal.getBoundingClientRect();
          const modalContentRect = innerContent.getBoundingClientRect();
          let contentHeight = modalRect.height - (modalContentRect.top - modalRect.top);
          if (modalConfirm) {
            const modalConfirmRect = modalConfirm.getBoundingClientRect();
            contentHeight -= modalConfirmRect.height;
          }
          innerContent.style.height = contentHeight + "px";
        }
      }
    }
  }

  openModal(id, direction) {
    this.setModalHeight();
    this.fixedPosY = window.pageYOffset;

    if (this.fixedFlg) {
      this.container.style.top = - this.fixedPosY + "px";
      this.container.style.height = window.innerHeight + this.fixedPosY + "px";
    }
    this.modalLib.openModal(id, this.fixedFlg, direction).done(() => {
      this.lockFlg = false;
    });

    const innerContent = this.getTargetModalContent(this.targetModal);
    const modalConfirm = this.targetModal.getElementsByClassName('modal__confirm')[0];
    if (innerContent) {
      innerContent.style.height = "";
      const modalRect = this.targetModal.getBoundingClientRect();
      const modalContentRect = innerContent.getBoundingClientRect();
      let contentHeight = modalRect.height - (modalContentRect.top - modalRect.top);
      if (modalConfirm) {
        const modalConfirmRect = modalConfirm.getBoundingClientRect();
        contentHeight -= modalConfirmRect.height;
      }
      if (modalContentRect.height != 0) {
        innerContent.style.height = contentHeight + "px";
      }
    }
  }

  closeModal() {
    if (this.lockFlg) return false;
    this.spModalMode = 0;
    this.modalLib.closeModal(this.fixedPosY, this.fixedFlg).done(() => {
      this.container.style.top = "";
      this.container.style.height = "";
    });
  }

  getTargetModalContent(targetModal) {
    let innerContent = targetModal.getElementsByClassName('js-modalInnerContent')[0];

    if (window.innerWidth >= this.breakPoint) {
      return innerContent;
    } else {
      if (innerContent) {
        const style = window.getComputedStyle(innerContent);
        if (style["overflow-y"] != "auto") {
          // SPで overflow-y: auto の設定が無い場合
          const modalContent = targetModal.getElementsByClassName('modalContent')[0];
          if (modalContent) {
            const modalContentStyle = window.getComputedStyle(modalContent);
            if (modalContentStyle["overflow-y"] == "auto") {
              // modalContent に overflow-y: auto の設定があれば返却
              innerContent = modalContent;
            }
          }
        }
      }

      return innerContent;
    }
  }
}
