import "./polyfill";
import "./jquery-imageready";
import "what-input";

import "./modules/";

import SetViewport from "./modules/SetViewport";

// SetViewport
new SetViewport();

