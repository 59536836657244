import _ from "underscore";

export default class Refinement {
  constructor() {
    this.container = document.getElementsByClassName("js-refinement");

    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    // リセットボタン
    if (this.container.length) {
      this.debouncedOnResetClick = _.debounce((container) => {
        this.resetForm(container);
      }, 0);
      Array.prototype.forEach.call(this.container, elm => {
        const resetBtn = elm.getElementsByClassName("js-refinementReset")[0];
        if (resetBtn) {
          resetBtn.addEventListener("click", e => {
            e.preventDefault();
            e.stopPropagation();
            this.debouncedOnResetClick(elm);
          });
        }
      });
    }
  }

  // reset
  resetForm(container) {
    const input = container.getElementsByTagName("input");
    if (input.length) {
      const names = [];
      Array.prototype.forEach.call(input, elm => {
        const type = elm.type;
        if (elm.disabled) {
          elm.disabled = false;
        }
        if (type == "checkbox") {
          elm.checked = false;
        } else if (type == "text") {
          elm.value = "";
        } else if (type == "radio") {
          // ラジオボタンの場合
          // 同じnameの最初の値だけチェック
          const name = elm.name;
          if (!names[name]) {
            elm.checked = true;
            names[name] = name;
          } else {
            elm.checked = false;
          }
        }
      });
    }

    const select = container.getElementsByTagName("select");
    if (select.length) {
      Array.prototype.forEach.call(select, selectElm => {
        if (selectElm.disabled) {
          selectElm.disabled = false;
          const parentElm = selectElm.parentNode;
          if (parentElm.classList.contains("-disabled")) {
            parentElm.classList.remove("-disabled");
          }
        }
        const option = selectElm.getElementsByTagName("option");
        if (option.length) {
          Array.prototype.forEach.call(option, optionElm => {
            optionElm.selected = optionElm.defaultSelected;
          });
        }
      });
    }
  }
}
