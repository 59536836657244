// PC右カラム スクロール固定処理
import _ from "underscore";
import IsPassiveSupport from "../lib/IsPassiveSupport";

export default class PcContentFixed {
  constructor(container, target) {
    this.container = document.getElementsByClassName(container)[0];
    this.target = this.container.getElementsByClassName(target)[0];
    this.width = this.target.dataset.width ? this.target.dataset.width : 3 / 10;
    this.offsetTop = this.target.dataset.top ? parseInt(this.target.dataset.top, 10) : 0;
    this.offsetBottom = this.target.dataset.bottom ? parseInt(this.target.dataset.bottom, 10) : 0;
    this.breakPoint = 768;
    this.timeout;
    this.init();
  }

  init() {
    if (!this.target) return false;

    // 固定要素の方が高い場合は何もしない
    if (this.target.clientHeight > this.container.clientHeight - 100) {
      return false;
    }

    // 固定要素が画面高さより大きい場合は何もしない
    const headerElm = document.getElementsByClassName("header")[0];
    if (headerElm) {
      if (window.innerHeight - (headerElm.clientHeight + this.target.clientHeight) < 100) {
        return false;
      }
    } else {
      if (window.innerHeight - this.target.clientHeight < 100) {
        return false;
      }
    }

    this.bindEvents();
    this.scroll();
  }

  bindEvents() {
    const self = this;
    const isPassive = new IsPassiveSupport();

    // Scroll Event
    this.onScroll = () => {
      if (this.timeout) {
        cancelAnimationFrame(this.timeout);
      }
      this.timeout = requestAnimationFrame(() => {
        self.scroll();
      });
    }
    document.addEventListener(
      "scroll",
      () => {
        this.onScroll();
      },
      isPassive.check() ? { passive: true } : false
    );

    // resize event
    const debouncedOnResize = _.debounce(() => {
      this.setPosition();
    }, 600);
    window.addEventListener("resize", () => {
      window.requestAnimationFrame(debouncedOnResize);
    }, false);
  }

  resetPosition() {
    this.target.classList.remove("-fixed");
    this.target.classList.remove("-bottom");
    this.target.style.width = "";
    this.target.style.left = "";
  }

  setPosition() {
    if (window.innerWidth < this.breakPoint) {
      this.resetPosition();
      return false;
    }

    const headerFixed = document.getElementsByClassName("js-headerFixed")[0];
    const headerFixedRect = headerFixed.getBoundingClientRect();
    const headerHeight = headerFixedRect.height + headerFixedRect.top;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const areaRect = this.container.getBoundingClientRect();
    const targetRect = this.target.getBoundingClientRect();
    const startPos = areaRect.top - headerHeight;
    const endPos = areaRect.top + areaRect.height - targetRect.height - 18 - headerHeight;
    const targetWidth = parseInt((areaRect.width * this.width), 10);
    const leftFlg = this.target.dataset.left?true:false;
    let targetLeft;
    if(leftFlg){
      targetLeft = areaRect.left;
    }else{
      targetLeft = areaRect.right - targetWidth;
    }

    if (startPos >= 18 - this.offsetTop) {
      this.resetPosition();
    } else {
      if (endPos >= 18 - this.offsetBottom) {
        this.target.classList.remove("-bottom");
        this.target.classList.add("-fixed");
        this.target.style.width = targetWidth + "px";
        this.target.style.left = targetLeft + "px";
      } else {
        this.target.classList.remove("-fixed");
        this.target.classList.add("-bottom");
        this.target.style.width = "";
        this.target.style.left = "";
      }
    }
  }

  scroll() {
    if (window.innerWidth < this.breakPoint) {
      // sp
      return false;
    }

    this.setPosition();
  }
}
