import _ from "underscore";
import $ from "jquery";
import 'slick-carousel';

export default class Recommend {
  constructor() {
    this.init();
  }

  init() {
    this.slider();
    this.recommendTab();
    this.recommendImg();
  }

  slider() {
    const self = this;
    const container = document.getElementsByClassName('js-recommendSlider');
    Array.prototype.forEach.call(container, elm => {
      const list = elm.getElementsByClassName('js-recommendSliderList')[0];
      self.slick(elm, list);
    });
  }

  slick(container, list) {
    const prevBtn = container.getElementsByClassName('js-recommendSliderPrev')[0];
    const nextBtn = container.getElementsByClassName('js-recommendSliderNext')[0];
    const sliderOpt = {
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 5,
      arrows: false,
      dots: true,
    };

    const changeControl = (currentNum, maxNum) => {
      if (maxNum <= 5) {
        prevBtn.classList.add('-hide');
        nextBtn.classList.add('-hide');
      }

      if (currentNum == 0) {
        prevBtn.classList.add('-end');
      } else {
        prevBtn.classList.remove('-end');
      }

      if (maxNum - currentNum <= 5) {
        nextBtn.classList.add('-end');
      } else {
        nextBtn.classList.remove('-end');
      }
    }

    $(list).on('init', (event, slick) => {
      changeControl(slick.currentSlide, slick.slideCount);
    });

    const slider = $(list).slick(sliderOpt);

    slider.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
      changeControl(nextSlide, slick.slideCount);
      this.recommendImg();
    });

    const debouncedOnPrevBtn = _.debounce(elm => {
      slider.slick('slickPrev');
    }, 0);

    const debouncedOnNextBtn = _.debounce(elm => {
      slider.slick('slickNext');
    }, 0);

    if (prevBtn) {
      prevBtn.addEventListener("click", e => {
        e.preventDefault();
        e.stopPropagation();
        debouncedOnPrevBtn();
      });
    }

    if (nextBtn) {
      nextBtn.addEventListener("click", e => {
        e.preventDefault();
        e.stopPropagation();
        debouncedOnNextBtn();
      });
    }

    const handle = mq => {
      if (mq.matches) {
        slider.not('.slick-initialized').slick(sliderOpt);
      } else {
        slider.slick("unslick");
      }
    }

    const mediaQuery = matchMedia('not all and (max-width: 767px)');
    handle(mediaQuery);
    mediaQuery.addListener(handle);
  }

  recommendTab() {
    if(document.getElementsByClassName('js-recommendTab').length) {
      $('.js-recommendTab').each(function(){
        const mediaQuery = matchMedia('not all and (max-width: 767px)');
        const $target = $(this);
        const $list = $target.find('.js-recommendTabList');
        const $trigger = $target.find('.js-recommendTabLink');
        const contents = '.js-recommendTabContent';
        $trigger.on('click', function(e){
          e.preventDefault();
          const id = $(this).attr('href');
          const $content = $(contents + id);
          const $slider = $content.find('.js-recommendSliderList');
          $trigger.removeClass('-current');
          $(this).addClass('-current');
          $target.find(contents).removeClass('-current');
          $content.addClass('-current');
          $target.find(contents).removeClass('-show');
          setTimeout(() => {
            $content.addClass('-show');
          }, 100);
          if (mediaQuery.matches) {
              $slider.slick('setPosition');
          }
        });
        if (!mediaQuery.matches) {
          $list.on('scroll', function(){
            $target.addClass('-scroll');
          });
        }
      });
    }
  }

  recommendImg() {
    $('.js-recommendSlider').find('img').each(function(i){
      const $target = $(this);
      var img = $('<img>');
      img.attr('src', $target.attr('src'));
      img.on('load', function() {
        const r = $target.width() / $target.height();
        if(r<=(128/183)){
          $target.addClass('-ct');
          $target.removeClass('-md');
        }else{
          $target.addClass('-md');
          $target.removeClass('-ct');
        }
      });
    });
  }
}
