import _ from "underscore";

export default class ModalRefinementSp {
  constructor() {
    this.container = document.getElementsByClassName("js-modalRefinementWrap");
    this.target = document.getElementsByClassName("js-modalRefinementSp");
    this.elmList = [];
    this.currentElm;
    this.nextElm;
    this.lockFlg = false;

    this.init();
  }

  init() {
    this.setList();
    this.initContent();
    this.bindEvents();
  }

  // カテゴリレベルとIDをキーにして配列に登録
  setList() {
    if (this.container.length) {
      Array.prototype.forEach.call(this.container, elm => {
        const level = elm.dataset.level;
        const id = elm.dataset.id;

        const obj = {
          level: level,
          id: id,
          elm: elm
        };
        this.elmList.push(obj);
      });
    }
  }

  bindEvents() {
    if (this.target.length) {
      this.debouncedOnClick = _.debounce(elm => {
        const level = elm.dataset.level;
        const id = elm.dataset.id;
        const direction = elm.dataset.direction;
        this.nextElm = this.getTargetElm(level, id);
        this.changeContent(direction);
      }, 0);
      Array.prototype.forEach.call(this.target, elm => {
        elm.addEventListener("click", e => {
          e.preventDefault();
          e.stopPropagation();
          if (this.lockFlg) return false;
          this.lockFlg = true;
          this.debouncedOnClick(elm);
        });
      });
    }

    // モーダルが閉じたタイミングを監視
    const observer = new MutationObserver(() => {
      const state = document.body.classList.contains('-modalOpen');
      if (!state) {
        // 初期化
        this.initContent();
      }
    }).observe(document.body, {
      attributes: true,
      attributeFilter: ['class']
    });

    // リセットボタン
    if (this.container.length) {
      this.debouncedOnResetClick = _.debounce((container) => {
        this.resetForm(container);
      }, 0);
      Array.prototype.forEach.call(this.container, elm => {
        const resetBtn = elm.getElementsByClassName("js-modalRefinementReset")[0];
        if (resetBtn) {
          resetBtn.addEventListener("click", e => {
            e.preventDefault();
            e.stopPropagation();
            this.debouncedOnResetClick(elm);
          });
        }
      });
    }
  }

  // 初期化
  initContent() {
    this.nextElm = "";
    this.lockFlg = false;
    if (this.container.length) {
      Array.prototype.forEach.call(this.container, elm => {
        const level = elm.dataset.level;
        const id = elm.dataset.id;

        this.removeAllClass(elm);

        if (level == "0" && id == "0") {
          this.currentElm = elm;
        } else {
          elm.classList.add("-hide");
        }
      });
    }
  }

  changeContent(direction) {
    const { prevClassName, nextClassName } = this.getDirectionName(direction);

    if (!(prevClassName && nextClassName)) return false;

    _.delay(() => {
      this.currentElm.classList.add(prevClassName);
    }, 40);
    _.delay(() => {
      this.currentElm.classList.add("-hide");
      this.currentElm.classList.add("-active");
    }, 450);

    this.removeAllClass(this.nextElm);
    this.nextElm.classList.add(nextClassName);
    _.delay(() => {
      this.setInnerHeight(this.nextElm);
      this.nextElm.classList.remove(nextClassName);
    }, 40);
    _.delay(() => {
      this.currentElm = this.nextElm;
      this.nextElm = "";
      this.lockFlg = false;
    }, 450);
  }

  removeAllClass(elm) {
    elm.classList.remove("-top");
    elm.classList.remove("-left");
    elm.classList.remove("-right");
    elm.classList.remove("-bottom");
    elm.classList.remove("-active");
    elm.classList.remove("-hide");
  }

  getTargetElm(level, id) {
    const targetObj = _.filter(this.elmList, obj => {
      return (obj.level == level && obj.id == id);
    })[0];
    return targetObj.elm;
  }

  getDirectionName(direction) {
    let prevClassName = "";
    let nextClassName = "";
    if (direction == "left") {
      prevClassName = "-right";
      nextClassName = "-left";
    } else if (direction == "right") {
      prevClassName = "-left";
      nextClassName = "-right";
    } else if (direction == "top") {
      prevClassName = "-bottom";
      nextClassName = "-top";
    } else if (direction == "bottom") {
      prevClassName = "-top";
      nextClassName = "-bottom";
    }
    return { prevClassName, nextClassName };
  }

  setInnerHeight(elm) {
    if (elm) {
      const innerContent = elm.getElementsByClassName('js-modalRefinementInnerContent')[0];
      if (innerContent) {
        innerContent.style.height = "";
        const elmRect = elm.getBoundingClientRect();
        const modalContentRect = innerContent.getBoundingClientRect();
        const contentHeight = elmRect.height - (modalContentRect.top - elmRect.top);
        if (contentHeight > 0) {
          innerContent.style.height = contentHeight + "px";
        }
      }
    }
  }

  // reset
  resetForm(container) {
    const input = container.getElementsByTagName("input");
    if (input.length) {
      const names = [];
      Array.prototype.forEach.call(input, elm => {
        const type = elm.type;
        if (elm.disabled) {
          elm.disabled = false;
        }
        if (type == "checkbox") {
          elm.checked = false;
        } else if (type == "text") {
          elm.value = "";
        } else if (type == "radio") {
          // ラジオボタンの場合
          // 同じnameの最初の値だけチェック
          const name = elm.name;
          if (!names[name]) {
            elm.checked = true;
            names[name] = name;
          } else {
            elm.checked = false;
          }
        }
      });
    }

    const select = container.getElementsByTagName("select");
    if (select.length) {
      Array.prototype.forEach.call(select, selectElm => {
        if (selectElm.disabled) {
          selectElm.disabled = false;
          const parentElm = selectElm.parentNode;
          if (parentElm.classList.contains("-disabled")) {
            parentElm.classList.remove("-disabled");
          }
        }
        const option = selectElm.getElementsByTagName("option");
        if (option.length) {
          Array.prototype.forEach.call(option, optionElm => {
            optionElm.selected = optionElm.defaultSelected;
          });
        }
      });
    }
  }
}
